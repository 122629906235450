export const searchFormConfig = {
  formItems: [
    {
      field: 'key',
      type: 'input',
      label: 'terminal.batch-name'
    },
    {
      field: 'value',
      type: 'input',
      label: 'terminal.batch-id'
    },
    {
      field: 'order',
      type: 'input',
      label: 'terminal.order'
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'general.status'
    // }
  ]
}

export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'key',
      label: 'terminal.batch-name'
    },
    {
      prop: 'value',
      label: 'terminal.batch-id'
    },
    {
      prop: 'order',
      label: 'terminal.order'
    },
    // {
    //   prop: 'status',
    //   label: 'general.status'
    // },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '300',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}

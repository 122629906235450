<template>
  <div class="page-search">
    <ws-form
      ref="formSearchComRef"
      v-bind="searchFormConfig"
      v-model="formData"
      @clearSelect="handleQueryClick"
    >
      <template #header>
        <p class="header" style="font-weight: bold; padding-left: 10px">
          {{ pageTitle }}
        </p>
        <hr
          style="
            margin-top: 10px;
            margin-bottom: 10px;
            width: 98%;
            margin-left: 1%;
            color: #e9e9eb;
            background-color: #e9e9eb;
            height: 1px;
            border: none;
          "
        />
      </template>
      <template #footer>
        <div class="handle-btns">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleQueryClick"
            size="small"
            >{{ $t('general.search') }}</el-button
          >
          <el-button
            icon="el-icon-refresh"
            @click="handleResetClick"
            size="small"
            >{{ $t('general.reset') }}</el-button
          >
          <el-button
            v-if="showBackBtn"
            icon="el-icon-back"
            @click="handleBackClick"
            size="small"
            plain
            >{{ $t('general.back') }}</el-button
          >
        </div>
      </template>
    </ws-form>
  </div>
</template>

<script setup>
import WsForm from '@/base-ui/form/src/index.js'
import {
  defineProps,
  ref,
  defineEmits,
  defineExpose,
  onMounted,
  watch,
  nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { convertDateStringToTimezoneOnlyDate } from '@/utils/util'

const props = defineProps({
  // 表单对象配置
  searchFormConfig: {
    type: Object,
    required: true
  },
  pageTitle: {
    type: String,
    required: true
  },
  showBackBtn: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['resetBtnClick', 'queryBtnClick'])

// 判断表单对象中的formItems是否存在
const formItems = ref(props.searchFormConfig.formItems || [])
watch(
  () => formItems.value,
  (newVal, oldVal) => {
    nextTick(() => {
      for (const item of newVal) {
        formOriginData[item.field] = item.defaultValue ? item.defaultValue : ''
      }
    })
  },
  {
    deep: true
  }
)
const resolveGlobalParamsOptions = async (formItems) => {
  for (const item of formItems) {
    if (item.isResolveGlobalParams && typeof item.handler === 'function') {
      try {
        const result = await item.handler()
        item.options = result
      } catch (error) {
        console.error(`Error resolving ${item.field} options:`, error)
        item.options = []
      }
    }
  }
}

onMounted(async () => {
  await resolveGlobalParamsOptions(formItems.value)
})

const formOriginData = {}
// 获取formItem中的每一项 并将其域值转为''
for (const item of formItems.value) {
  formOriginData[item.field] = item.defaultValue ? item.defaultValue : ''
}

const formData = ref(formOriginData)
const formObject = ref(formItems)

const formSearchComRef = ref()
// 重置操作时
const handleResetClick = () => {
  formData.value = formOriginData
  formSearchComRef.value.handleResetValidate()
  emit('resetBtnClick')
}

const handleTransDateParams = () => {
  const datePickerObject = {}
  formObject.value.map((item) => {
    if (
      item.type === 'datepicker' &&
      item.dateFieldName &&
      item.dateFieldName.length === 2
    ) {
      const field = item.field
      // 转换日期时间格式

      datePickerObject[item.dateFieldName[0]] =
        convertDateStringToTimezoneOnlyDate(
          formData.value[field]?.[0],
          item.dateFieldName[0]
        ) || ''
      datePickerObject[item.dateFieldName[1]] =
        convertDateStringToTimezoneOnlyDate(
          formData.value[field]?.[1],
          item.dateFieldName[1]
        ) || ''
    }
  })
  return datePickerObject
}

const getCurrentFieldValues = () => {
  const dateRangeParams = handleTransDateParams()

  return {
    ...formData.value,
    ...dateRangeParams
  }
}

// 查询操作时
const handleQueryClick = async () => {
  const res = await formSearchComRef.value.handleValidate()
  if (res) {
    formData.value = getCurrentFieldValues()
    emit('queryBtnClick', formData.value)
  }
}

const router = useRouter()
const handleBackClick = () => {
  router.go(-1)
}

defineExpose({
  formData,
  getCurrentFieldValues
})
</script>

<style lang="scss" scoped>
.handle-btns {
  text-align: center;
  padding: 0 50px 20px 0;
}
</style>
